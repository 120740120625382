<template>
  <div>
    <div class="flix-form-group">
      <backBtn :onClick="function() { onChange() }"/>
    </div>
    <label class="flix-html-label">{{ $tc('message.tel', 1) }}</label>
    <div class="flix-form-group">
      <input type="text" class="flix-form-control" :value="user.tel" readonly="true">
    </div>
    <div class="flix-row">
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.tel', 1)}) }}</h3>
        </div>
      </div>
      <div class="flix-col-md-6">
        <label class="flix-html-label">{{ $t('message.add', {name: $tc('message.tel', 1)}) }} <span class="flix-text-danger">*</span></label>
        <div class="flix-form-group">
          <input v-on:keyup="checkSaveButton" type="email" class="flix-form-control" v-model="newTel" spellcheck="false">
        </div>
      </div>
      <div class="flix-col-md-6">
        <label class="flix-html-label">{{ $t('message.repeatTel') }} <span class="flix-text-danger">*</span></label>
        <div class="flix-form-group">
          <input v-on:keyup="checkSaveButton" type="email" class="flix-form-control" v-model="repeatTel" spellcheck="false">
        </div>
      </div>
    </div>
    <div class="flix-row" :key="saveBtn">
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <saveBtn :class="{'flix-disabled': !saveBtn}" :settings="{block: true}" :onClick="function () { if (saveBtn) { saveData(user) } }">
            <template v-slot:text>
              {{ $t('message.save', {name: $tc('message.tel', 1)}) }}
            </template>
          </saveBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    onChange: Function,
    saveData: Function
  },
  data () {
    return {
      user: JSON.parse(JSON.stringify(this.$store.getters.user)),
      newTel: '',
      repeatTel: '',
      saveBtn: false
    }
  },
  methods: {
    checkSaveButton () {
      this.saveBtn = true
      if (this.newTel !== this.repeatTel) {
        this.saveBtn = false
        return false
      }
      if (this.newTel.length < 5 || this.repeatTel.length < 5) {
        this.saveBtn = false
        return false
      }
      this.user.tel = this.newTel.toLowerCase()
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
